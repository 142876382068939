import React from "react";
import { useEffect, useState } from "react";
import sanityClient from "../client";
import BlockContent from "@sanity/block-content-to-react";
import { Modal, ModalBody, Button } from "reactstrap";
import { Helmet } from "react-helmet";

// import history from 'react-router-dom';

export default function Music(props) {
  const [musicData, setMusicData] = useState(null);
  const [seoData, setSEO] = useState(null);

  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectId, setSelectId] = useState({
    _id: null,
  });
  const query = `*[_type == "music"] | order(publishedAt desc) {title, _id, body, apple, buy, slug, spotify, label, publishedAt, mainImage{ asset->{ _id, url}, alt }}`;
  const query2 = `*[_type == "seotools"] {seo}`;

  useEffect(() => {
    sanityClient
      .fetch(query)
      .then((data) => setMusicData(data))
      .then(console.log("ok"))
      .catch(console.error);
  }, []);

  useEffect(() => {
    sanityClient
      .fetch(query2)
      .then((data) => setSEO(data))
      .then(console.log("ok"))
      .catch(console.error);
  }, []);

  // useEffect(()=>{
  //   console.log(musicData);
  //   // let result1 = musicData.publishedAt;
  //   // let ano = result1.slice(0,5);
  //   // console.log(ano);
  // },[])

  const toggle = (disco) => {
    setSelected(disco);
    setModal(true);
    setSelectId({
      ...selectId,
      _id: "",
    });
  };

  const closeModal = () => {
    setSelected(null);
    setModal(false);
  };

  if (!musicData) return <div className="font1"> Loading...</div>;


  return (
    <>
      {seoData &&
        seoData.map((seos) => (
          <>
            <Helmet>
              <meta
                name="description"
                content={
                  seos.seo.seo_title +
                  ", " +
                  seos.seo.meta_description +
                  ", " +
                  seos.seo.focus_synonyms.join(", ")
                }
              />
              <title itemProp="name" lang="en">
                {seos.seo.seo_title} Music{" "}
              </title>

              <link href="https://wastedfates.com/music" rel="canonical"></link>
            </Helmet>
          </>
        ))}

      <div className="App-div-one animate__animated animate__fadeIn">
        <div className="py-10 font1">LISTEN</div>
        <div>
          {musicData &&
            musicData.map((disco, index, _id) => (
              <article
                key={index}
                onClick={() => toggle(disco)}
                className="centro py-8"
              >
                <img
                  className="object-cover w-6/12 align-middle pb-4"
                  src={disco.mainImage.asset.url}
                  style={{
                    display: "block",
                    margin: "0 auto",
                  }}
                />
                <h3 className="font1 pt-4 pb-4">
                  {disco.title}&nbsp;-&nbsp; &#91; &nbsp;
                  {disco.label}
                  &nbsp; &#93; &nbsp;-&nbsp;
                  {disco.publishedAt.slice(0, 4)}
                </h3>
                <hr
                  className="w-50 "
                  style={{ margin: "0 auto", display: "block", height: "2px" }}
                />
              </article>
            ))}

          <Modal
            key={selected && selected._id}
            isOpen={modal}
            id={selected && selected._id}
            fullscreen
            size="xl"
            contentClassName="custom-modal-stylex"

            // toggle={toggle}
            // modalTransition={{ timeout: 100 }}
          >
            <ModalBody
              style={{
                backgroundColor: "#E4DDCC",
                alignItems: "center",
                height: "100%",
                width: "100%",
                display: "block",
                margin: "0 auto",
                className: "animate__animated animate__fadeIn",
              }}
            >
              <Button
              color="dark"
              outline
              size="sm"
                style={{
                  display: "flex",
                  position: "absolute",
                  fontSize: "1rem",
                  top: "2%",
                  right: " 1%",
                }}
                onClick={closeModal}
              >
                X
              </Button>
              <div className="fila xl:columns-2  lg:columns-2	 md:columns-2 sm:columns-1 xs:columns-1  ">
                <figure>
                  <div className="uno  xs:w-full sm:w-full  md:w-full lg:px-14 md:px-2 sm:px-20 lg:w-10/12 py-8 sm:pt-3 xs:pt-3">
                    <img
                      src={selected && selected.mainImage.asset.url}
                      alt={selected && selected.title}
                      className="align-middle "
                    />
                    <h1 className="font1 text-4xl pt-3">
                      {selected && selected.title}
                    </h1>
                    <h2 className="font1 text-2xl pt-1">
                      {selected && selected.label}
                    </h2>
                    <h2 className="font1 text-2xl">
                      {selected && selected.publishedAt.slice(0, 4)}
                    </h2>
                  </div>
                </figure>

                <figure>
                  <div className="dos xs:w-full sm:w-full  md:w-full lg:w-full  w-full px-2 ">
                    <br />
                    <span>
                      <BlockContent
                        blocks={selected && selected.body}
                        projectId="fvo0c4rf"
                        dataset="wastedfates"
                        className="sm:px-20 md:px-2 lg:px-2 text-justify lg:pt-5 md:pt-5 sm:pt-1"
                      />
                      &nbsp;
                      <div  className="sm:px-20 md:px-2 lg:px-2 align-middle">
                        {selected && selected.buy ? 
                         <a href={selected.buy} target="_blank">
                         <Button
                           color="dark"
                           outline
                           size="sm"
                           className="sm:px-20 md:px-2 lg:px-2 text-justify mb-4"
                         >
                           Buy
                         </Button>
                       </a>
                        :
                        " "
                        }
                      
                     
                      &nbsp;
                      {selected && selected.apple ? <a href={selected.apple} target="_blank">
                        <Button
                          color="dark"
                          outline
                          size="sm"
                          className="sm:px-20 md:px-2 lg:px-2 text-justify mb-4"
                        >
                          Apple music
                        </Button>
                      </a>
                      :
                      " "
                      
                      }
                     

                      </div>
                  
                    </span>
                    {selected && selected.spotify ?
  <iframe
  className="sm:px-20 md:px-2 lg:px-2 align-middle"
   src={selected.spotify}
   width="100%"
   height="180"
   frameBorder="0"
   allowfullscreen=""
   allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
 ></iframe>
                    :
                    " "

                    }
                  
                  </div>
                </figure>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </>
  );
}
