import React, { useEffect, useState } from "react";
import getYouTubeId from "get-youtube-id";
import imageUrlBuilder from "@sanity/image-url";
import YouTube from "react-youtube";
import sanityClient from "../client";
import BlockContent from "@sanity/block-content-to-react";
import { Helmet } from "react-helmet";
import vides from "./videosizes";

const serializers = {
  types: {
    youtube: ({ node }) => {
      const { url } = node;
      const id = getYouTubeId(url);
      return (
        <>
          <div className="py-10">
            <YouTube videoId={id} className="youtu" />
          </div>
        </>
      );
    },
  },
};

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

function Videos() {
  const [seoData, setSEO] = useState(null);

  const [videoData, setVideoData] = useState(null);
  // const [modal, setModal] = useState(null);
  // const [selected, setSelected] = useState(null);
  const query = `*[_type == "videos"] | order(publishedAt desc) {
    title, body, publishedAt, title2, body2, album, linkvimeo
  }`;
  const query2 = `*[_type == "seotools"] {seo}`;

  useEffect(() => {
    sanityClient
      .fetch(query)
      .then((data) => setVideoData(data))
      .then(console.log("ok"))
      .catch(console.error);
  }, []);

  useEffect(() => {
    sanityClient
      .fetch(query2)
      .then((data) => setSEO(data))
      .then(console.log("ok_"))
      .then(
        window.addEventListener("load", function () {
          console.log("All assets loaded");
        })
      )
      .catch(console.error);
  }, []);

  if (!videoData) return <div className="font1"> Loading...</div>;


  // useEffect(() =>{
  //   vides();
  // },[]);

  return (
    <>
      {seoData &&
        seoData.map((seos) => (
          <>
            <Helmet>
              <meta
                name="description"
                content={
                  seos.seo.seo_title +
                  ", " +
                  seos.seo.meta_description +
                  ", " +
                  seos.seo.focus_synonyms.join(", ")
                }
              />
              <title itemProp="name" lang="en">
                {seos.seo.seo_title} Videos{" "}
              </title>

              <link href="https://wastedfates.com/music" rel="canonical"></link>
            </Helmet>
          </>
        ))}

      <div className="App-div-one animate__animated animate__fadeIn">
        <div className="py-10 font1">SEE</div>

        {videoData &&
          videoData.map((videos, index) => (
            <div key={index}>
              <BlockContent
                key={index}
                className="flex justify-center	py-6 px-6 "
                blocks={videos && videos.body}
                projectId="fvo0c4rf"
                dataset="wastedfates"
                serializers={serializers}
              />
              {typeof videos.linkvimeo === "undefined" ? (
                " "
              ) : (
                <div className=" py-10 vimeovid">
                  <iframe
                    title="vimeo-player"
                    src={videos && videos.linkvimeo}
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              )}
              {videos && videos.title ? (
                <h3 className="font1">{videos.title}</h3>
              ) : (
                ""
              )}
              {videos && videos.album ? (
                <h6 className="font1">{videos.album}</h6>
              ) : (
                ""
              )}
              
              {videos && videos.title2 ? ( 
                <h6 className="font1"> &#91; {videos.title2}  &#93;  </h6>
              ) : (
                ""
              )}{" "}
             
              {videos && videos.publishedAt ? (
                <h6 className="font1"> - {videos.publishedAt.slice(0, 4)} - </h6>
              ) : (
                ""
              )}
              <br />
              <div
                className="w-50 pb-3"
                style={{ margin: "0 auto", display: "block" }}
              >
                <span className="text-base font1 ">
                  <BlockContent
                    className=" justify-center"
                    blocks={videos && videos.body2}
                    projectId="fvo0c4rf"
                    dataset="wastedfates"
                  />
                </span>
              </div>
              <hr
                className="w-50"
                style={{ margin: "0 auto", display: "block", height: "2px" }}
              />
            </div>
          ))}
        <br />
      </div>
    </>
  );
}

export default Videos;
