import React from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { useState } from "react";
// import logo from '../smiley.png';

const Navbar1 = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <div>
      <Navbar color="faded" light>
        <NavbarBrand className="me-auto titulo font1 animate__animated animate__fadeIn" href="/">
          WASTED FATES
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} />
        <Collapse navbar isOpen={!collapsed}>
          <Nav className="navo" navbar>
            {/* <NavItem>
          <NavLink href="/components/">
            Store
          </NavLink>
        </NavItem> */}
            <NavItem>
              <NavLink className="linkos" href="/videos">
                SEE
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="linkos" href="/music">
                LISTEN
              </NavLink>
            </NavItem>
            <UncontrolledDropdown inNavbar nav>
              <DropdownToggle
                caret
                nav
                style={{ backgroundColor: "rgba(0,0,0,0)" }}
              >
                FOLLOW
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem href="https://instagram.com/wastedfates">
                  INSTAGRAM
                </DropdownItem>
                <DropdownItem href="https://twitter.com/WastedFates">
                  TWITTER{" "}
                </DropdownItem>
                {/* <DropdownItem divider /> */}
                <DropdownItem href="https://facebook.com/WastedFates">
                  FACEBOOK
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Navbar1;
