import './App.css';
import Navbar1 from './components/navbar';
import Landing from './components/landing';
import Music from './components/music';
import Videos from './components/videos';
import {BrowserRouter as Router ,Route, Routes } from 'react-router-dom';



function App() {
  return (
        <div className="App">

    <Router>
    <Navbar1/>
  <Routes>
  <Route caseSensitive={false} title="Wasted Fates" element={<Landing />} path='/' exact />
  <Route caseSensitive={false} title="Music" element={<Music />} path='/music' />
  <Route caseSensitive={false} title="Videos" element={<Videos />} path='/videos' />
 
  </Routes>
  </Router>

       
     
    </div>
  );
}

export default App;
