import React from "react";
import logo from '../logo-01.png';
import { useEffect, useState } from "react";
import sanityClient from "../client";
import {Helmet} from "react-helmet";



function Landing() {
   const [seoData, setSEO] = useState(null);

const query = '*[_type == "seotools"] { seo }';

useEffect(() => {
sanityClient
.fetch(query)
.then((data) => setSEO(data))
.then(console.log("oki"))
.catch(console.error)
},[]);

useEffect(() => {
    console.log(seoData);
});

if (!seoData) return <div className="font1"> Loading...</div>;


        return (
            <>

            
{seoData && seoData.map((seos) => (
    <>
    <Helmet>
<meta name="description" content={seos.seo.meta_description} />
</Helmet>
<div className="App-div-one animate__animated animate__fadeIn">
            <img src={logo} className="App-logo7" alt="logo" />
      <img src={logo} className="App-logo6" alt="logo" />
        <img src={logo} className="App-logo5" alt="logo" />
        <img src={logo} className="App-logo4" alt="logo" />
        <img src={logo} className="App-logo3" alt="logo" />
        <img src={logo} className="App-logo2-1" alt="logo" />
        <img src={logo} className="App-logo2" alt="logo" />
        <img src={logo} className="App-logo" alt="logo" />
        <img src={logo} className="App-logo0" alt="logo" />
        <img src={logo} className="App-logo01" alt="logo" />
        <img src={logo} className="App-logo02" alt="logo" />
        <p className='titulo font1'>
         WASTED FATES
        </p>
            </div>

    </>
))}

            
              
            
            </>
        )
    
}

export default Landing;